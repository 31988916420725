export const UserInternal = {
  data: () => ({
    noEmptyRule: v => !!v || 'Campo obrigatório',
    loginRule: v => /.+@.+\..+/.test(v) || 'Login precisa ser um email válido'
  })
}

export const UserExternal = {
  data: () => ({
    noEmptyRule: v => !!v || 'Campo obrigatório',
    passwordRule: v => (v || '').length >= 8 || 'Senha deve ter no mínimo 8 caracteres',
    loginRule: v => /.+@.+\..+/.test(v) || 'Login precisa ser um email válido',
    passwordConfirm: ''
  })
}
