<template>
  <v-row>
    <v-col v-if="$apollo.loading" cols="12">
      <v-row justify="center">
        <v-col cols="5">
          <mf-loading-content :loading="true"></mf-loading-content>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else cols="12">
      <v-row justify="center">
        <v-col cols="5">
          <mf-text-input v-model="user.name" :rules="[noEmptyRule]" label="Nome"></mf-text-input>
        </v-col>
        <v-col cols="5">
          <mf-text-input v-model="user.login" :rules="[loginRule]" label="Email"></mf-text-input>
        </v-col>
        <v-col cols="5">
          <mf-text-input
            v-model="user.password"
            :rules="[passwordRule, noEmptyRule]"
            name="input-10-1"
            label="Senha"
            message="Senha deve ter no mínimo 8 caracteres"
            :counter="0"
          ></mf-text-input>
        </v-col>
        <v-col cols="5">
          <mf-text-input
            v-model="passwordConfirm"
            :rules="[passwordRule, noEmptyRule, user.password === passwordConfirm || 'Senhas não são identicas']"
            name="input-10-1"
            label="Confirmar senha"
            :counter="0"
          ></mf-text-input>
        </v-col>
        <v-col cols="5">
          <v-autocomplete v-model="user.franchisesId" label="Rede" :items="adminAccounts" item-text="name" item-value="_id" outlined></v-autocomplete>
        </v-col>
        <v-col cols="5">
          <v-autocomplete
            v-model="user.accounts"
            :rules="[noEmptyRule]"
            label="Contas"
            :items="accountsList"
            item-text="name"
            item-value="_id"
            outlined
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption"> (+{{ user.accounts.length - 1 }} contas selecionadas) </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { UserExternal } from '@/modules/users/mixins/user.js'
import { QUERY_GET_ACCOUNTS_FOR_USERS } from '@/modules/users/graphql'
import { QUERY_GET_ACCOUNTS } from '@/modules/accounts/graphql'
export default {
  mixins: [UserExternal],
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      franchisesId: '',
      accountsList: [],
      accounts: [],
      units: []
    }
  },
  apollo: {
    adminAccounts: {
      query: QUERY_GET_ACCOUNTS,
      fetchPolicy: 'network-only',
      variables: {
        franchise: true
      }
    },
    availableAccounts: {
      query: QUERY_GET_ACCOUNTS_FOR_USERS,
      fetchPolicy: 'no-cache',
      variables() {
        return Object.assign({}, { input: this.user.franchisesId ? { franchiseId: this.user.franchisesId } : {} })
      },
      update({ getAccountsForUsers }) {
        this.accountsList = getAccountsForUsers
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['accounts']
        }
      }
    }
  }
}
</script>
